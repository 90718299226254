import React from "react";
import { graphql } from "gatsby";

import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Container from "../components/container";
import BlockText from "../components/block-text";
import Layout from "../containers/layout";

import * as styles from "./legal.module.css";

export const query = graphql`
  query PrivacyPolicyPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    policy: sanityLegal {
      _rawPrivacyPolicy
    }
  }
`;

const PrivacyPolicyPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  const policy = data.policy;

  return (
    <Layout>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />

      <Container>
        <h1 className={styles.title}>Privacy Policy</h1>
        <div>
          <BlockText blocks={policy._rawPrivacyPolicy} />
        </div>
      </Container>
    </Layout>
  );
};

export default PrivacyPolicyPage;
